<ion-header>
  <ion-toolbar>
    <div class="provider-header">
      <div class="provider-title">{{ 'commons.providers' | translate }}</div>
      <ion-button class="is-toggle-size-btn" (click)="handleArrowBtnClicked()">
        <span slot="icon-only" class="ph" [ngClass]="uiIsCollapsed ? 'ph-caret-right' : 'ph-caret-left'"></span>
      </ion-button>
    </div>
  </ion-toolbar>
  <ng-container *ngIf="showSearch">
    <ion-toolbar class="has-searchbar">
      <div class="provider-search">
        <div><ion-searchbar mode="ios" [placeholder]="'Search Providers'" (ionInput)="handleKeywordChange($event)" debounce="300"></ion-searchbar></div>
      </div>
    </ion-toolbar>
  </ng-container>
</ion-header>
<ion-content>
  <ul class="provider-list">
    <li class="provider-item" *ngFor="let provider of presentedProviders" [ngClass]="{ 'is-selected': provider.selected }" (click)="handleProviderSelection(provider)">
      <span class="provider-logo"></span>
      <span class="provider-name">{{ provider?.label }}</span>
      <ng-container *ngIf="provider.selected"><span class="ph ph-x"></span></ng-container>
    </li>
  </ul>
</ion-content>
