import { inject, Injectable } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { ICasinoGameDetails, ICasinoGameLaunchDetails, IJackpot } from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { CASINO_CATEGORIES_BETPLATFORM } from 'bp-framework/dist/env-specific/betplatform/casino/casino.const';
import { CasinoGame, CasinoLaunchDetails, CasinoPayload, IJackpotItem } from 'bp-framework/dist/env-specific/betplatform/casino/casino.interface';
import { mapCasinoGames, mapCasinoJackpots } from 'bp-framework/dist/env-specific/betplatform/casino/casino.mappers';

import { AuthService, BpCasinoApiService } from 'bp-angular-library';

import { CasinoAbstractService, UserAbstractService } from '../../env-abstracts';

import { JackpotWinnerComponent } from '../../../../shared/components/jackpot-winner/jackpot-winner.component';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasinoBetplatformService extends CasinoAbstractService {
  private bpCasinoApiService: BpCasinoApiService = inject(BpCasinoApiService);
  private translateService: TranslateService = inject(TranslateService);
  private authService: AuthService = inject(AuthService);

  private userAbstractService: UserAbstractService = inject(UserAbstractService);
  private modalController: ModalController = inject(ModalController);

  public jackpots$: BehaviorSubject<IJackpot[] | null> = new BehaviorSubject<IJackpot[] | null>(null);

  constructor() {
    super();
    this.getJackpotsList();
  }

  public async getCasinoGames(): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: CasinoPayload<CasinoGame> | null = await this.bpCasinoApiService.getAllCasinoGames();
        const mapped: ICasinoGameDetails<any, any>[] = response?.results && Array.isArray(response?.results) ? mapCasinoGames(response.results) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveTheListOfCasinoGames')));
      }
    });
  }

  public async getCasinoCategories(): Promise<Partial<IListItem<number>>[]> {
    return Promise.resolve(CASINO_CATEGORIES_BETPLATFORM);
  }

  public async getDetailsToLaunchGame(gameId: number): Promise<ICasinoGameLaunchDetails> {
    return new Promise<ICasinoGameLaunchDetails>(async (resolve, reject) => {
      try {
        const response: CasinoLaunchDetails | null = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId);
        resolve({ url: response?.launchUrl, token: response?.token } as ICasinoGameLaunchDetails);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUrlForPlayingCasinoGame')));
      }
    });
  }

  // TODO: Remove this method and replace it with getDetailsToLaunchGame or vice versa
  // TODO: Check if there are some other api calls that are not used and remove them
  public async getSingleCasinoGameToPlay(gameId: number): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: any = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId);
        resolve(response);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUrlForPlayingCasinoGame')));
      }
    });
  }

  public async getJackpotsListForPlayers(): Promise<IJackpot[] | null> {
    return new Promise<IJackpot[] | null>(async (resolve, reject) => {
      try {
        const jackpots: IJackpotItem[] | null = await this.bpCasinoApiService.getJackpotsListForPlayers();

        if (!jackpots) {
          return resolve(null);
        }

        resolve(mapCasinoJackpots(jackpots));
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveTheListOfCasinoGames')));
      }
    });
  }

  /////// JACKPOTS SERVICE

  public processJackpotAwardedEvent(playerId: string, jackpot: IJackpot): void {
    if (playerId === this.authService.user$.value?.id) {
      this.presentJackpotWinnerModal(jackpot);
      this.userAbstractService.updateUserWithProfileData();
    }
  }

  public async getJackpotsList(): Promise<void> {
    const tmpValue: IJackpot[] | null = await this.getJackpotsListForPlayers();
    this.jackpots$.next(tmpValue);
  }

  public updateJackpotValue(jackpot: IJackpot): void {
    const tmpJackpots: IJackpot[] = this.jackpots$.value || [];
    const index: number = tmpJackpots.findIndex(item => item.id === jackpot.id);

    if (index !== -1) {
      tmpJackpots[index] = { ...tmpJackpots[index], ...jackpot };
      this.jackpots$.next(tmpJackpots);
    }
  }

  private async presentJackpotWinnerModal(jackpotDetails: IJackpot): Promise<void> {
    const modal = await this.modalController.create({
      component: JackpotWinnerComponent,
      componentProps: {
        jackpotDetails
      },
      cssClass: 'full-screen-modal'
    });

    await modal.present();

    setTimeout(() => {
      modal.dismiss();
    }, 10000);

    // const { data, role } = await modal.onWillDismiss();

    // if (role === 'confirm') {
    //   // console.log('SoftKeyboardComponent closed and confirmed');
    // }
  }
}
