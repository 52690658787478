<ion-header>
  <ion-toolbar>
    <a class="has-logo" href="">
      <img class="is-logo" src="./env-assets/images/main-logo.svg" alt="Logo" loading="lazy" />
    </a>
    <ion-buttons slot="end">
      <ion-button (click)="closeMenu()"><span class="ph ph-x" slot="icon-only"></span></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <nav class="main-pages-nav">
    <ul>
      <ng-container *ngFor="let group of sideMenuGroups">
        <!-- Items without children -->
        <li [routerLink]="'/' + group?.path" routerLinkActive="active" ariaCurrentWhenActive="page" (click)="closeMenu()">
          <a ariaCurrentWhenActive="page">
            <span aria-hidden="true" class="ph" [ngClass]="group?.icon"></span>
            {{ group.t9nKey || '' | translate }}
          </a>
        </li>
      </ng-container>
    </ul>
  </nav>
  <nav class="secondary-pages-nav">
    <ion-menu-toggle>
      <ul>
        <li *ngFor="let item of secondaryPages">
          <a [routerLink]="item.path" routerLinkActive="active">{{ item?.label }}</a>
        </li>
      </ul>
    </ion-menu-toggle>
  </nav>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <div class="footer-row">
      <lib-theme-toggle></lib-theme-toggle>
      <lib-language-button></lib-language-button>
    </div>
    <div class="footer-row has-app-version">
      {{ appVersion }}
    </div>
  </ion-toolbar>
</ion-footer>
