import { NgIf } from '@angular/common';
import { Component, DestroyRef, HostListener, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { IonicModule, Platform } from '@ionic/angular';

import { IEnvApiBase, IEnvConfigPlayerFrontend } from 'bp-framework/dist/configuration/configuration.interface';

import { PROJECT_ENV_CONFIG_TOKEN } from 'bp-angular-library';

import { CasinoProvidersComponent } from './pages-casino/components/casino-providers/casino-providers.component';
import { SidemenuMyProfileComponent } from './shared/components/menu-my-profile/sidemenu-my-profile.component';
import { SidemenuPrimaryComponent } from './shared/components/sidemenu-primary/sidemenu-primary.component';

import { DESKTOP_BREAKPOINT } from './pages-surfpoint/models/surfpoint.const';
import { debounceTime } from 'rxjs';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';

// register Swiper custom elements
register();

@Component({
  selector: 'ion-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgIf, IonicModule, SidemenuPrimaryComponent, SidemenuMyProfileComponent, CasinoProvidersComponent]
})
export class AppComponent implements OnInit {
  private projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase> = inject<IEnvConfigPlayerFrontend<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);
  private platform: Platform = inject(Platform);
  private destroyRef: DestroyRef = inject(DestroyRef);

  public disableSidemenu!: boolean;
  public isDesktopResolution!: boolean;

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: any): void {
    // TODO: Check should we keep these event handlers inside the AppComponent or move them to some other service
    // The reason why we might need them is the fact that Reload/Refresh on the browser won't trigger ngOnDestroy
    // IMPORTANT: Figure out a mechanism to unsubscribe EVERYTHING when the user leaves the app or refreshes the page (sse, rxjs, etc.)
    // IMPORTANT: TODO: In the newer versions of Angular, HostListener might be deprecated? Check if there is a better way to handle these events
    // TODO: We might need to implement logic that will check if the user token has expired when the user minimize app on the phone and then open it again
    console.log('');
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any): void {
    console.log('');
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychangeHandler(event: any): void {
    // TODO: Check should we keep these event handlers inside the AppComponent or move them to some other service
    // TODO: Investigage is this the best way to handle visibility change events
    // TODO: The reason why we need this is the fact that user can minimize the app on the phone and then open it again. We need to check if the token has expired or not in that time frame
    // TODO: Check if we need to implement 'visibilitychange' event on other/indivitual pages to ensure that the data is up to date when the user opens the app again
    // if (document?.hidden === false) {
    // TODO: Check if we need to refresh the user data when the user opens/focus the app again
    //   this.envAdapterService.updateUserWithProfileData();
    // }
  }

  constructor() {
    this.disableSidemenu = this.projectConfig?.features?.frontendMode === 'surfpoint';
    this.isDesktopResolution = this.platform?.width() >= DESKTOP_BREAKPOINT;
  }

  ngOnInit(): void {
    this.observePlatformWidth();
  }

  private observePlatformWidth(): void {
    this.platform?.resize?.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(200)).subscribe(() => {
      this.isDesktopResolution = this.platform?.width() >= DESKTOP_BREAKPOINT;
    });
  }
}
