import { inject, Injectable } from '@angular/core';

import { DialogsService } from 'bp-angular-library';

import { UserAbstractService } from 'src/app/core/env-specific/env-abstracts';

export type SignUpOptionType = 'phone' | 'email' | 'social' | 'telegram';
export type SiSuViewType = 'sign-in' | 'sign-up';

@Injectable({
  providedIn: 'root'
})
export class SignInSignUpService {
  public currentView: SiSuViewType = 'sign-up';
  public signUpOptionType: SignUpOptionType = 'email';

  public setView(view: SiSuViewType): void {
    this.currentView = view;
  }

  public setSignUpOptionType(view: SignUpOptionType): void {
    this.signUpOptionType = view;
  }
}
