<ion-split-pane contentId="main" when="false">
  <!-- Main Sidemenu -->
  <ion-menu menuId="primary-sidemenu" contentId="main" swipeGesture="false" [disabled]="disableSidemenu">
    <app-sidemenu-primary></app-sidemenu-primary>
  </ion-menu>
  <!-- My Profile Sidemenu -->
  <ion-menu menuId="my-profile-sidemenu" contentId="main">
    <app-sidemenu-my-profile *ngIf="!isDesktopResolution" class="ion-page"></app-sidemenu-my-profile>
  </ion-menu>
  <!-- Casino Providers Sidemenu -->
  <ion-menu menuId="casino-providers-sidemenu" contentId="main">
    <app-casino-providers *ngIf="!isDesktopResolution" class="ion-page"></app-casino-providers>
  </ion-menu>
  <div class="ion-page" id="main" class="router-wrapper">
    <ion-router-outlet></ion-router-outlet>
  </div>
</ion-split-pane>
