import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { FlagImgComponent } from 'bp-angular-library';

import { MaskitoDirective } from '@maskito/angular';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import { maskitoGetCountryFromNumber, maskitoPhoneOptionsGenerator } from '@maskito/phone';
import metadata from 'libphonenumber-js/min/metadata';

@Component({
  selector: 'app-phone-input',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, FormsModule, MaskitoDirective, FlagImgComponent],
  templateUrl: './phone-input.component.html',
  styleUrl: './phone-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneInputComponent {
  public phoneNumber = '';

  // TODO: Check here do we need to provide 'countryIsoCode' as a parameter to the maskitoPhoneOptionsGenerator function that matches the country code of the currently selected language or user location.
  public maskObj: MaskitoOptions = maskitoPhoneOptionsGenerator({ metadata, strict: false, countryIsoCode: 'DE' });
  readonly maskPredicate: MaskitoElementPredicate = async el => (el as HTMLIonInputElement).getInputElement();

  protected get countryIsoCode(): string {
    return maskitoGetCountryFromNumber(this.phoneNumber, metadata) ?? '';
  }
}
