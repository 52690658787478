<!-- Username -->
<ion-input aria-label="Username" fill="outline" placeholder="Username">
  <span slot="start" class="ph ph-user"></span>
</ion-input>
<!-- Password -->
<app-password-input [label]="'Password'" [placeholder]="'Password'" (handleInputChange)="handlePasswordChange($event)"></app-password-input>
<!-- Confirm password -->
<app-password-input [label]="'Confirm Password'" [placeholder]="'Confirm Password'" (handleInputChange)="handlePasswordConfirmChange($event)"></app-password-input>
<!-- Phone number -->
<app-phone-input></app-phone-input>
<!-- Confirm -->
<div>I confirm that I am of legal age and accept the <a>website rules</a></div>
<!-- Submit button -->
<ion-button class="is-submit-button">Sign Up</ion-button>
