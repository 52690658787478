import { CommonModule } from '@angular/common';
import { Component, DestroyRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { IonicModule, MenuController } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { IListItem } from 'bp-framework/dist/common/common.interface';

import { CasinoService } from '../../casino.service';

import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-casino-providers',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
  templateUrl: './casino-providers.component.html',
  styleUrls: ['./casino-providers.component.scss']
})
export class CasinoProvidersComponent implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private casinoService: CasinoService = inject(CasinoService);
  private menuController: MenuController = inject(MenuController);

  @Input() showSearch = true;
  @Input() collapsible = false;
  @Input() isCollapsed = false;

  @HostBinding('class.collapsible') get isCollapsible() {
    return this.collapsible;
  }

  @HostBinding('class.is-collapsed') get uiIsCollapsed() {
    return this.isCollapsed;
  }

  public presentedProviders: Partial<IListItem<string>>[] = [];
  public changesOnProviders$: Subject<Partial<IListItem<string>>[]> = new Subject<Partial<IListItem<string>>[]>();

  private mobileSearchKeyword = '';

  ngOnInit(): void {
    this.casinoService.providers$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((providers: Partial<IListItem<string>>[]) => {
      this.presentedProviders = this.filterProviderLabelsByKeyword(this.mobileSearchKeyword, this.casinoService.providers);
    });

    this.changesOnProviders$.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300)).subscribe((providersWithUpdatedSelectedState: Partial<IListItem<string>>[]) => {
      const updatedProviders = this.updateProviderSelections(providersWithUpdatedSelectedState, this.casinoService.providers);
      this.casinoService.handleSelectedProvidersChange(updatedProviders);
    });
  }

  public handleProviderSelection(provider: Partial<IListItem<string>>): void {
    provider.selected = !provider.selected;
    this.changesOnProviders$.next(this.presentedProviders);
  }

  public handleArrowBtnClicked(): void {
    if (!this.collapsible) {
      this.menuController.close();
      return;
    }

    if (this.collapsible) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  public handleKeywordChange(event: Event): void {
    if (!this.showSearch) {
      return;
    }

    this.mobileSearchKeyword = (event as CustomEvent)?.detail?.value;
    this.presentedProviders = this.filterProviderLabelsByKeyword(this.mobileSearchKeyword, this.casinoService.providers);
  }

  private filterProviderLabelsByKeyword(keyword: string, list: Partial<IListItem<string>>[]): Partial<IListItem<string>>[] {
    return list.filter(item => item.label?.toLowerCase().includes(keyword.toLowerCase()));
  }

  private updateProviderSelections(listWithUpdatedSelections: Partial<IListItem<string>>[], allProviders: Partial<IListItem<string>>[]): Partial<IListItem<string>>[] {
    // Create a map to store the selection state of each item in listWithUpdatedSelections
    const selectionMap = new Map<string, boolean>();

    // Populate the map with the selection states
    listWithUpdatedSelections.forEach(item => {
      if (item.value) {
        selectionMap.set(item.value, item.selected || false);
      }
    });

    // Update the selection state of each item in allProviders based on the map
    return allProviders.map(provider => {
      if (provider.value && selectionMap.has(provider.value)) {
        provider.selected = selectionMap.get(provider.value);
      }
      return provider;
    });
  }
}
