import { Router, Routes } from '@angular/router';

import { AuthService, I18nService, StorageService } from 'bp-angular-library';

import { ContentAbstractService, UserAbstractService } from '../env-specific/env-abstracts';

import { SUPPORTED_LANGUAGE_OPTIONS } from 'src/app/shared/models/i18n/i18n.const';

export function appInitFactory(
  router: Router,
  storageService: StorageService,
  authService: AuthService,
  i18nService: I18nService,
  contentAbstractService: ContentAbstractService,
  userAbstractService: UserAbstractService
): () => Promise<boolean> {
  return () =>
    new Promise(async (resolve, reject) => {
      await storageService.initializeStorageService();
      await authService.handleAppInitialization();
      await i18nService.initializeLanguage(SUPPORTED_LANGUAGE_OPTIONS);
      userAbstractService.watchForLanguageChange();

      const appRoutes: Routes = contentAbstractService.buildAllRoutes();
      router.resetConfig(appRoutes);

      resolve(true);
    });
}
