<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="handleLogin()">
  <!-- Username -->
  <ion-input aria-label="Username" fill="outline" placeholder="Username" formControlName="username">
    <span slot="start" class="ph ph-user"></span>
  </ion-input>
  <!-- Password -->
  <app-password-input [label]="'Password'" [placeholder]="'Password'" (handleInputChange)="handlePasswordChange($event)"></app-password-input>
  <!-- Forgot your password? -->
  <div class="is-forgot-pass-link">Forgot your password?</div>
  <!-- Submit button -->
  <ion-button class="is-submit-button" type="submit" [disabled]="formGroup.invalid">Sign In</ion-button>
  <!-- Don't have an account? -->
  <div class="is-dont-have-account-link">Don't have an account? <a (click)="siSuService.setView('sign-up')">Sign Up</a></div>
</form>
