import { Routes } from '@angular/router';

import { INavGroup } from 'bp-framework/dist/common/common.interface';

import { ROUTE_PATHS } from 'src/app/shared/models/routing/routing.const';

//#region Routes for RULES related pages
export const ROUTES_FOR_RULES: Routes = [
  {
    path: ROUTE_PATHS.rules.path,
    children: [
      {
        path: ROUTE_PATHS.rules.children.casinoRules,
        loadComponent: () => import('../../../../pages-rules/casino-rules/casino-rules.component').then(m => m.CasinoRulesComponent)
      },
      {
        path: ROUTE_PATHS.rules.children.liveCasinoRules,
        loadComponent: () => import('../../../../pages-rules/live-casino-rules/live-casino-rules.component').then(m => m.LiveCasinoRulesComponent)
      },
      {
        path: ROUTE_PATHS.rules.children.virtualSportsRules,
        loadComponent: () => import('../../../../pages-rules/virtual-sport-rules/virtual-sport-rules.component').then(m => m.VirtualSportRulesComponent)
      },
      {
        path: '',
        redirectTo: ROUTE_PATHS.rules.children.casinoRules,
        pathMatch: 'full'
      }
    ]
  }
];
//#endregion

//#region Routes for SUPPORT related pages
export const ROUTES_FOR_SUPPORT: Routes = [
  {
    path: ROUTE_PATHS.support.path,
    children: [
      {
        path: ROUTE_PATHS.support.children.contact,
        loadComponent: () => import('../../../../pages-support/contact/contact.component').then(m => m.ContactComponent)
      },
      {
        path: ROUTE_PATHS.support.children.privacyPolicy,
        loadComponent: () => import('../../../../pages-support/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent)
      },
      {
        path: ROUTE_PATHS.support.children.termsOfUse,
        loadComponent: () => import('../../../../pages-support/terms-of-use/terms-of-use.component').then(m => m.TermsOfUseComponent)
      },
      {
        path: ROUTE_PATHS.support.children.responsibleGamingPolicy,
        loadComponent: () => import('../../../../pages-support/responsible-gaming-policy/responsible-gaming-policy.component').then(m => m.ResponsibleGamingPolicyComponent)
      },
      {
        path: ROUTE_PATHS.support.children.amlKycPolicy,
        loadComponent: () => import('../../../../pages-support/aml-kyc-policy/aml-kyc-policy.component').then(m => m.AmlKycPolicyComponent)
      },
      {
        path: '',
        redirectTo: ROUTE_PATHS.support.children.contact,
        pathMatch: 'full'
      }
    ]
  }
];
//#endregion

//#region Routes for MY PROFILE related pages
export const ROUTES_FOR_MY_PROFILE: Routes = [
  {
    path: ROUTE_PATHS.myProfile.path,
    children: [
      {
        path: ROUTE_PATHS.myProfile.children.myAccount,
        loadComponent: () => import('../../../../pages-my-profile/my-account/my-account.component').then(m => m.MyAccountComponent)
      },
      {
        path: ROUTE_PATHS.myProfile.children.deposit,
        loadComponent: () => import('../../../../pages-my-profile/my-deposit/my-deposit.component').then(m => m.MyDepositComponent)
      },
      {
        path: ROUTE_PATHS.myProfile.children.withdraw,
        loadComponent: () => import('../../../../pages-my-profile/my-withdrawal/my-withdrawal.component').then(m => m.MyWithdrawalComponent)
      },
      {
        path: ROUTE_PATHS.myProfile.children.myGameHistory,
        loadComponent: () => import('../../../../pages-my-profile/my-game-history/my-game-history.component').then(m => m.MyGameHistoryComponent)
      },
      {
        path: ROUTE_PATHS.myProfile.children.myAffiliate,
        loadComponent: () => import('../../../../pages-my-profile/my-affiliate/my-affiliate.component').then(m => m.MyAffiliateComponent)
      },
      {
        path: ROUTE_PATHS.myProfile.children.myVerification,
        loadComponent: () => import('../../../../pages-my-profile/my-verification/my-verification.component').then(m => m.MyVerificationComponent)
      },
      {
        path: '',
        redirectTo: ROUTE_PATHS.myProfile.children.myAccount,
        pathMatch: 'full'
      }
    ]
  }
];
//#endregion

//#region Routes for header navigation on the SECONDARY PAGES (Rules, Support, My Profile)
export const RULES_NAV_GROUP: Partial<INavGroup>[] = [
  {
    path: `/${ROUTE_PATHS.rules.path}`,
    label: 'Rules',
    t9nKey: '',
    sortOrder: 3,
    icon: 'ph-list',
    children: [
      {
        path: `/${ROUTE_PATHS.rules.path}/${ROUTE_PATHS.rules.children.casinoRules}`,
        label: 'Casino Rules',
        t9nKey: '',
        sortOrder: 1,
        icon: ''
      },
      {
        path: `/${ROUTE_PATHS.rules.path}/${ROUTE_PATHS.rules.children.liveCasinoRules}`,
        label: 'Live Casino Rules',
        t9nKey: '',
        sortOrder: 2,
        icon: ''
      },
      {
        path: `/${ROUTE_PATHS.rules.path}/${ROUTE_PATHS.rules.children.virtualSportsRules}`,
        label: 'Virtual Sport Rules',
        t9nKey: '',
        sortOrder: 3,
        icon: ''
      }
    ]
  }
];
//#endregion

//#region Routes for SUPPORT related pages
export const SUPPORT_NAV_GROUP: Partial<INavGroup>[] = [
  {
    path: `/${ROUTE_PATHS.support.path}`,
    label: 'Support',
    t9nKey: '',
    sortOrder: 2,
    icon: 'ph-list',
    children: [
      {
        path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.contact}`,
        label: 'Contact',
        t9nKey: '',
        sortOrder: 1,
        icon: ''
      },
      {
        path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.privacyPolicy}`,
        label: 'Privacy Policy',
        t9nKey: '',
        sortOrder: 2,
        icon: ''
      },
      {
        path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.termsOfUse}`,
        label: 'Terms of Use',
        t9nKey: '',
        sortOrder: 3,
        icon: ''
      },
      {
        path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.responsibleGamingPolicy}`,
        label: 'Responsible Gaming Policy',
        t9nKey: '',
        sortOrder: 4,
        icon: ''
      },
      {
        path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.amlKycPolicy}`,
        label: 'AML & KYC Policy',
        t9nKey: '',
        sortOrder: 5,
        icon: ''
      }
    ]
  }
];
//#endregion

//#region Routes for MY PROFILE related pages

export const MY_PROFILE_NAV_GROUP: Partial<INavGroup>[] = [
  {
    path: `/${ROUTE_PATHS.myProfile.path}`,
    label: 'My Profile',
    t9nKey: '',
    sortOrder: 1,
    icon: 'ph-list',
    children: [
      {
        path: `/${ROUTE_PATHS.myProfile.path}/${ROUTE_PATHS.myProfile.children.myAccount}`,
        label: 'My Account',
        t9nKey: '',
        sortOrder: 1,
        icon: 'ph-list'
      },
      {
        path: `/${ROUTE_PATHS.myProfile.path}/${ROUTE_PATHS.myProfile.children.deposit}`,
        label: 'Deposit',
        t9nKey: '',
        sortOrder: 2,
        icon: 'ph-list'
      },
      {
        path: `/${ROUTE_PATHS.myProfile.path}/${ROUTE_PATHS.myProfile.children.withdraw}`,
        label: 'Withdraw',
        t9nKey: '',
        sortOrder: 3,
        icon: 'ph-list'
      },
      {
        path: `/${ROUTE_PATHS.myProfile.path}/${ROUTE_PATHS.myProfile.children.myGameHistory}`,
        label: 'Game History',
        t9nKey: '',
        sortOrder: 4,
        icon: 'ph-list'
      },
      {
        path: `/${ROUTE_PATHS.myProfile.path}/${ROUTE_PATHS.myProfile.children.myAffiliate}`,
        label: 'Affiliate',
        t9nKey: '',
        sortOrder: 5,
        icon: 'ph-list'
      },
      {
        path: `/${ROUTE_PATHS.myProfile.path}/${ROUTE_PATHS.myProfile.children.myVerification}`,
        label: 'Verification',
        t9nKey: '',
        sortOrder: 6,
        icon: 'ph-list'
      },
      {
        path: `/${ROUTE_PATHS.support.path}`,
        label: 'Support',
        t9nKey: '',
        sortOrder: 7,
        icon: 'ph-list'
      }
    ]
  }
];

export const SECONDARY_PAGES_NAV_GROUPS: Partial<INavGroup>[] = [...MY_PROFILE_NAV_GROUP, ...RULES_NAV_GROUP, ...SUPPORT_NAV_GROUP];
//#endregion

//#region Routes for the FOOTER NAVIGATION
export const SITE_FOOTER_NAVIGATION: Partial<INavGroup>[] = [
  {
    path: '',
    label: 'Games',
    t9nKey: '',
    sortOrder: 1,
    icon: '',
    children: [
      {
        path: `/${ROUTE_PATHS.casino}`,
        label: 'Casino',
        t9nKey: '',
        sortOrder: 1,
        icon: ''
      },
      {
        path: `/${ROUTE_PATHS.casinoLive}`,
        label: 'Live Casino',
        t9nKey: '',
        sortOrder: 2,
        icon: ''
      },
      {
        path: `/${ROUTE_PATHS.virtualSports}`,
        label: 'Virtual Sport',
        t9nKey: '',
        sortOrder: 3,
        icon: ''
      },
      {
        path: `/${ROUTE_PATHS.virtualGames}`,
        label: 'Virtual Betting',
        t9nKey: '',
        sortOrder: 4,
        icon: ''
      }
    ]
  },
  ...RULES_NAV_GROUP,
  ...SUPPORT_NAV_GROUP,
  {
    path: '',
    label: 'Promo',
    t9nKey: '',
    sortOrder: 1,
    icon: '',
    children: [
      {
        path: `/${ROUTE_PATHS.myProfile.path}/${ROUTE_PATHS.myProfile.children.myAffiliate}`,
        label: 'Affiliate',
        t9nKey: '',
        sortOrder: 1,
        icon: ''
      }
    ]
  }
];
//#endregion
