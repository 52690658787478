import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IonicModule, MenuController } from '@ionic/angular';

import { IListItem, INavGroup, INavItem } from 'bp-framework/dist/common/common.interface';

import { AuthService } from 'bp-angular-library';

import { MAIN_ROUTE_PATHS } from '../../models/routing/routing.const';
import { MY_PROFILE_NAV_GROUP } from 'src/app/core/env-specific/betplatform/routes/routes.utils';

const FOOTER_ITEMS: Partial<IListItem<string>>[] = [
  {
    iconName: 'ph-list',
    label: 'Sign Out',
    value: 'sign-out'
  }
];

@Component({
  selector: 'app-sidemenu-my-profile',
  standalone: true,
  imports: [CommonModule, IonicModule],
  templateUrl: './sidemenu-my-profile.component.html',
  styleUrl: './sidemenu-my-profile.component.scss'
})
export class SidemenuMyProfileComponent implements OnInit {
  public authService: AuthService = inject(AuthService);
  private router: Router = inject(Router);
  private menuController: MenuController = inject(MenuController);

  @Input() showHeader = true;

  public menuItems: Partial<IListItem<string>>[] = [];
  public footerItems: Partial<IListItem<string>>[] = FOOTER_ITEMS;

  ngOnInit(): void {
    this.menuItems = this.mapMenuItemsToListItems(MY_PROFILE_NAV_GROUP);
  }

  private mapMenuItemsToListItems(menuItems: Partial<INavGroup>[]): Partial<IListItem<string>>[] {
    return menuItems.flatMap(
      (group: Partial<INavGroup>) =>
        group.children?.map((child: Partial<INavItem>) => ({
          label: child.label,
          labelT9nKey: child.t9nKey,
          value: child?.path?.split('/').pop(), // Assuming the last part of the path is the value
          iconName: child.icon,
          sortOrder: child.sortOrder
        })) || []
    );
  }

  public handleMenuItemSelected(item: Partial<IListItem<string>>): void {
    if (item?.value === 'sign-out') {
      this.handleSignOut();
      this.handleClose();
      return;
    }

    const matchingItem: Partial<INavItem> | undefined = MY_PROFILE_NAV_GROUP[0].children?.find(
      (child: Partial<INavItem>) => child?.path && item?.value && child.path.includes(item.value)
    );

    if (matchingItem?.path) {
      this.router.navigateByUrl(matchingItem.path);
    }

    this.handleClose();
  }

  private async handleSignOut(): Promise<void> {
    await this.authService.logout();

    const isMenuOpen = await this.menuController.isOpen();
    if (isMenuOpen) {
      await this.menuController.close();
    }
    // TODO: In here, we should actually figure out the best route to redirect user to
    // There could be cases when the user is on the Casino, Betting or other pages that are 'public. In that case, we don't need to redirect him.
    // We should just clear the user data and let him stay on the page. Other parts of the application should handle the fact that the user is not logged in.
    this.router.navigateByUrl(MAIN_ROUTE_PATHS.casino);
  }

  public handleClose(): void {
    this.menuController.close();
  }
}
