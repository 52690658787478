<ion-searchbar
  class="casino-searchbar"
  mode="ios"
  #searchbar
  [debounce]="300"
  (ionFocus)="handleSearchFocusEvent()"
  (ionInput)="handleInputChangeEvent($event)"
  (ionClear)="handleInputClearEvent()"
  [placeholder]="'commons.search' | translate"
  [value]="casinoService.searchKeyword$ | async"></ion-searchbar>
