import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { DialogsService } from 'bp-angular-library';

import { CasinoService } from '../../casino.service';

import { CasinoSearchbarComponent } from '../casino-searchbar/casino-searchbar.component';

@Component({
  selector: 'app-casino-search-modal',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, CasinoSearchbarComponent],
  templateUrl: './casino-search-modal.component.html',
  styleUrl: './casino-search-modal.component.scss'
})
export class CasinoSearchModalComponent implements OnInit {
  private dialogsService: DialogsService = inject(DialogsService);
  private casinoService: CasinoService = inject(CasinoService);

  @Input() showHeader = true;

  ngOnInit(): void {
    console.log('CasinoSearchModalComponent initialized');
  }

  public handleInputChange(value: string): void {
    this.casinoService.handleKeywordChange(value);
  }

  public handleClose(): void {
    this.dialogsService.dismissModal();
  }
}
