import { inject, Injectable } from '@angular/core';

import { ICasinoGameDetails, ICasinoGameLaunchDetails, IJackpot } from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { CasinoCategories } from 'bp-framework/dist/env-specific/1x2team/casino/casino.enum';
import { CasinoCategory, CasinoGame, CasinoGamePlay, CasinoGameTypes, CasinoPayload, ICasinoJackpot } from 'bp-framework/dist/env-specific/1x2team/casino/casino.interface';
import { mapCasinoCategories, mapCasinoGames, mapCasinoJackpots, mapSurfpointCasinoGames } from 'bp-framework/dist/env-specific/1x2team/casino/casino.mappers';
import { IPayloadResults, ICasinoGameDetails as SurfpointGameDetails } from 'bp-framework/dist/env-specific/1x2team/casino/surfpoint.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { AuthService, CasinoApiService, SurfpointApiService } from 'bp-angular-library';

import { CasinoAbstractService } from '../../env-abstracts';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Casino1x2teamService extends CasinoAbstractService {
  private casinoApiService: CasinoApiService = inject(CasinoApiService);
  private authService: AuthService = inject(AuthService);
  // TODO: Check can we replace the SurfpointApiService with the CasinoApiService for the 1x2team provider
  private surfpointApiService: SurfpointApiService = inject(SurfpointApiService);

  public jackpots$: BehaviorSubject<IJackpot[] | null> = new BehaviorSubject<IJackpot[] | null>(null);

  public async getCasinoGames(): Promise<ICasinoGameDetails<any, any>[]> {
    // TODO: 1x2team does not have casino games functionality. Check how to handle this
    return Promise.resolve([]);
  }

  public async getCasinoCategories(): Promise<Partial<IListItem<number>>[]> {
    return new Promise<Partial<IListItem<number>>[]>(async (resolve, reject) => {
      try {
        const response: CasinoPayload<CasinoCategory> | null = await this.casinoApiService.getCasinoCategories();
        const mapped: Partial<IListItem<number>>[] = response?.results && Array.isArray(response?.results) ? mapCasinoCategories(response.results) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed retrieve the list of casino categories'));
      }
    });
  }

  public async getAllCasinoGames(): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: CasinoPayload<CasinoGame> | null = await this.casinoApiService.getAllCasinoGames();
        const mapped: ICasinoGameDetails<CasinoGameTypes, CasinoCategories>[] = response?.results && Array.isArray(response?.results) ? mapCasinoGames(response.results) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed retrieve the list of casino games'));
      }
    });
  }

  public async getAllCasinoGamesForSurfpoint(): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: IPayloadResults<SurfpointGameDetails> | null = await this.surfpointApiService.getAvailableCasinoGames();
        const mapped: ICasinoGameDetails<any, any>[] = response?.results && Array.isArray(response?.results) ? mapSurfpointCasinoGames(response.results as any) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed retrieve the list of casino games'));
      }
    });
  }

  public async getDetailsToLaunchGame(gameId: number): Promise<ICasinoGameLaunchDetails> {
    return new Promise<ICasinoGameLaunchDetails>(async (resolve, reject) => {
      try {
        const response: CasinoGamePlay | null = await this.casinoApiService.getCasinoGamePlayUrl(gameId);
        resolve({ url: response?.url, token: response?.token } as ICasinoGameLaunchDetails);
      } catch (error) {
        return reject(new Error('Failed retrieve the game launch url'));
      }
    });
  }

  public async getJackpotsListForPlayers(): Promise<any> {
    // TODO: Implement this method and check how to handle the 1x2Team fetching for the jackpot. On BetPlatform, we might have list of jackpots, while on the 1x2team, we have only one. We should have some kind of adapter.
    return Promise.resolve([]);
  }

  public async getJackpots(): Promise<IJackpot[] | null> {
    return new Promise<IJackpot[] | null>(async (resolve, reject) => {
      try {
        const user: Partial<IUserDetails> | null = this.authService.user$.value;
        const jackpot: ICasinoJackpot | null = await this.casinoApiService.getJackpot(user?.account?.agent || '');

        if (!jackpot) {
          return resolve(null);
        }

        resolve(mapCasinoJackpots([jackpot]));
      } catch (error) {
        return reject(new Error('Failed retrieve the list of jackpots'));
      }
    });
  }

  /////// JACKPOTS SERVICE

  public processJackpotAwardedEvent(playerId: string, jackpot: IJackpot): void {
    // TODO: Revisit this logic
  }

  public async getJackpotsList(): Promise<void> {
    // TODO: Revisit this logic
  }

  public updateJackpotValue(jackpot: IJackpot): void {
    // TODO: Revisit this logic
  }
}
