<ng-container *ngIf="showHeader">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="handleClose()"><span class="ph ph-x" slot="icon-only"></span></ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</ng-container>
<ion-content>
  <div class="is-content-wrapper">
    <!-- Row -->
    <div class="is-row">
      <p class="is-welcome-txt">Welcome to</p>
      <app-brand-logo class="is-brand-logo"></app-brand-logo>
    </div>
    <!-- Sign In view -->
    <app-sign-in-view *ngIf="siSuService.currentView === 'sign-in'"></app-sign-in-view>
    <!-- Sign Up view -->
    <app-sign-up-view *ngIf="siSuService.currentView === 'sign-up'"></app-sign-up-view>
  </div>
</ion-content>
