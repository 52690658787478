import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { PasswordInputComponent } from '../../../password-input/password-input.component';
import { PhoneInputComponent } from '../../../phone-input/phone-input.component';

@Component({
  selector: 'app-sign-up-with-email',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, PhoneInputComponent, PasswordInputComponent],
  templateUrl: './sign-up-with-email.component.html',
  styleUrl: './sign-up-with-email.component.scss'
})
export class SignUpWithEmailComponent {
  public handlePasswordChange(value: string): void {
    //empty
    console.log(value);
  }

  public handlePasswordConfirmChange(value: string): void {
    //empty
    console.log(value);
  }
}
