import { Routes } from '@angular/router';

import { ICasinoGameDetails, ICasinoGameLaunchDetails, IJackpot } from 'bp-framework/dist/casino/casino.interface';
import { IListItem, INavGroup, INavigationItem } from 'bp-framework/dist/common/common.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { BehaviorSubject } from 'rxjs';

export abstract class UserAbstractService {
  abstract loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null>;
  abstract refreshToken(): Promise<Partial<IUserDetails> | null>;
  abstract getUserProfile(): Promise<Partial<IUserDetails> | null>;
  abstract patchUserLanguage(langAlpha2Code: string): Promise<Partial<IUserDetails> | null>;
  abstract updateUserWithProfileData(): Promise<Partial<IUserDetails> | null>;
  abstract watchForLanguageChange(): void;
}

export abstract class CasinoAbstractService {
  abstract getCasinoGames(): Promise<ICasinoGameDetails<any, any>[]>;
  abstract getDetailsToLaunchGame(gameId: number): Promise<ICasinoGameLaunchDetails>;
  abstract getCasinoCategories(): Promise<Partial<IListItem<number>>[]>;
  abstract jackpots$: BehaviorSubject<IJackpot[] | null>;
  abstract getJackpotsListForPlayers(): Promise<any>;
  abstract updateJackpotValue(jackpot: IJackpot): void;
  abstract processJackpotAwardedEvent(playerId: string, jackpot: IJackpot): void;
}

export abstract class BettingAbstractService {
  abstract test(): void;
}

export abstract class BackendEventsAbstractService {
  abstract subscribeToBackendEvents(): void;
}

export abstract class ContentAbstractService {
  abstract buildAllRoutes(): Routes;
  abstract buildSidemenuRoutes(): Partial<INavGroup>[];
  abstract buildMainMenuRoutes(): Partial<INavigationItem<number>>[];
  abstract buildSiteFooterNavLinks(): Partial<INavGroup>[];
  abstract buildSecondaryPagesNavLinks(): Partial<INavGroup>[];
}
