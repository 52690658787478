import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IEnvApiBase, IEnvConfigPlayerFrontend } from 'bp-framework/dist/configuration/configuration.interface';

import { AuthService, I18nService, PROJECT_ENV_CONFIG_TOKEN, StorageService } from 'bp-angular-library';

import { BackendEventsAbstractService, BettingAbstractService, CasinoAbstractService, ContentAbstractService, UserAbstractService } from './app/core/env-specific/env-abstracts';

import { BackendEvents1x2teamService } from './app/core/env-specific/1x2team/services/backend-events-1x2team.service';
import { Betting1x2teamService } from './app/core/env-specific/1x2team/services/betting-1x2team.service';
import { Casino1x2teamService } from './app/core/env-specific/1x2team/services/casino-1x2team.service';
import { Content1x2teamService } from './app/core/env-specific/1x2team/services/content-1x2team.service';
import { User1x2teamService } from './app/core/env-specific/1x2team/services/user-1x2team.service';
import { BackendEventsBetplatformService } from './app/core/env-specific/betplatform/services/backend-events-betplatform.service';
import { BettingBetplatformService } from './app/core/env-specific/betplatform/services/betting-betplatform.service';
import { CasinoBetplatformService } from './app/core/env-specific/betplatform/services/casino-betplatform.service';
import { ContentBetplatformService } from './app/core/env-specific/betplatform/services/content-betplatform.service';
import { UserBetplatformService } from './app/core/env-specific/betplatform/services/user-betplatform.service';
import { ErrorHandlerService } from './app/core/error-handling/error-handler.service';

import { AppComponent } from './app/app.component';

import { appInitFactory } from './app/core/initializer/initializers';
import { AuthenticationInterceptor } from './app/core/interceptors/auth/authentication.interceptor';
import { environment } from 'src/environments/environment';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter([]),
    importProvidersFrom(
      BrowserModule,
      IonicModule.forRoot({
        mode: 'md',
        rippleEffect: false,
        swipeBackEnabled: false
      }),
      IonicStorageModule.forRoot({
        name: '__appdb',
        driverOrder: [Drivers.LocalStorage]
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    { provide: PROJECT_ENV_CONFIG_TOKEN, useValue: environment },
    {
      provide: ContentAbstractService,
      useFactory: (projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase>) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new ContentBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new Content1x2teamService();
        }

        throw new Error('Invalid provider');
      },
      deps: [PROJECT_ENV_CONFIG_TOKEN]
    },
    {
      provide: UserAbstractService,
      useFactory: (projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase>) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new UserBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new User1x2teamService();
        }

        throw new Error('Invalid provider');
      },
      deps: [PROJECT_ENV_CONFIG_TOKEN]
    },
    {
      provide: CasinoAbstractService,
      useFactory: (projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase>) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new CasinoBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new Casino1x2teamService();
        }

        throw new Error('Invalid provider');
      },
      deps: [PROJECT_ENV_CONFIG_TOKEN]
    },
    {
      provide: BettingAbstractService,
      useFactory: (projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase>) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new BettingBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new Betting1x2teamService();
        }

        throw new Error('Invalid provider');
      },
      deps: [PROJECT_ENV_CONFIG_TOKEN]
    },
    {
      provide: BackendEventsAbstractService,
      useFactory: (projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase>) => {
        if (projectConfig?.api?.provider === 'betplatform') {
          return new BackendEventsBetplatformService();
        } else if (projectConfig?.api?.provider === '1x2team') {
          return new BackendEvents1x2teamService();
        }

        throw new Error('Invalid provider');
      },
      deps: [PROJECT_ENV_CONFIG_TOKEN]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [Router, StorageService, AuthService, I18nService, ContentAbstractService, UserAbstractService],
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    provideAnimations()
  ]
}).catch(err => console.error(err));
