import { inject, Injectable } from '@angular/core';

import { ILoginPayload, IProfile } from 'bp-framework/dist/env-specific/1x2team/user/user.interface';
import { transformLoginDataToUserDetails, transformProfileDataToUserDetails } from 'bp-framework/dist/env-specific/1x2team/user/user.mappers';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { AuthService, CoreApiService } from 'bp-angular-library';

import { UserAbstractService } from '../../env-abstracts';

@Injectable({
  providedIn: 'root'
})
export class User1x2teamService extends UserAbstractService {
  private coreApiService: CoreApiService = inject(CoreApiService);
  private authService: AuthService = inject(AuthService);

  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const authResponse: Partial<ILoginPayload> | null = await this.coreApiService.authenticatePlayerWithUsernameAndPassword(username, password);

        if (!authResponse?.token) {
          return reject(new Error('Failed to login! Check your credentials'));
        }

        await this.authService.userAuthChanged(transformLoginDataToUserDetails(authResponse, {}));

        const userProfile: Partial<IProfile> | null = await this.coreApiService.getProfile();

        if (!userProfile?.id) {
          return reject(new Error('Failed to retreive user details!'));
        }

        const user: Partial<IUserDetails> | null = await this.authService.userAuthChanged(transformLoginDataToUserDetails(authResponse, userProfile));
        resolve(user);
      } catch (error) {
        return reject(new Error('Failed to login. Please check your username or password or try again later!'));
      }
    });
  }

  public async refreshToken(): Promise<Partial<IUserDetails> | null> {
    // TODO: 1x2team does not have refresh token functionality. Check how to handle this
    return Promise.resolve(null);
  }

  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: Partial<IProfile> | null = await this.coreApiService.getProfile();
        resolve(userProfile ? transformProfileDataToUserDetails(userProfile) : null);
      } catch (error) {
        return reject(new Error('Failed retrieve of user profile data'));
      }
    });
  }

  public async patchUserLanguage(langAlpha2Code: string): Promise<Partial<IUserDetails> | null> {
    // TODO: 1x2team does not have language patch functionality. Check how to handle this
    return Promise.resolve(null);
  }

  public async updateUserWithProfileData(): Promise<Partial<IUserDetails> | null> {
    // TODO: 1x2team does not have user update functionality. Check how to handle this
    return Promise.resolve(null);
  }

  public watchForLanguageChange(): void {
    // TODO: 1x2team does not have functionality to save selected language to backend (user profile).
  }
}
