<ng-container *ngIf="showHeader">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="handleClose()"><span class="ph ph-x" slot="icon-only"></span></ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</ng-container>
<ion-content>
  <div class="user-section">
    <div class="user-avatar">
      <i class="ph ph-user"></i>
    </div>
    <div class="user-name">{{ authService.user?.username }}</div>
  </div>
  <ul class="menu-list">
    <li class="menu-item" (click)="handleMenuItemSelected(item)" *ngFor="let item of menuItems">
      <span class="menu-icon ph" [ngClass]="item?.iconName || ''" aria-hidden="true"></span>
      <span class="menu-text">{{ item?.label }}</span>
    </li>
  </ul>
</ion-content>
<ion-footer>
  <ul class="menu-list">
    <li class="menu-item" (click)="handleMenuItemSelected(item)" *ngFor="let item of footerItems">
      <span class="menu-icon ph" [ngClass]="item?.iconName || ''" aria-hidden="true"></span>
      <span class="menu-text">{{ item?.label }}</span>
    </li>
  </ul>
</ion-footer>
