import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Platform } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';

import { ICasinoGameDetails } from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { IDialogResponse } from 'bp-framework/dist/dialogs/dialog.interface';
import { DIALOG_DISMISS_ROLES } from 'bp-framework/dist/dialogs/dialogs.const';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { DialogsService } from 'bp-angular-library';

import { CasinoAbstractService } from '../core/env-specific/env-abstracts';

import { SignInSignUpComponent } from '../shared/components/sign-in-sign-up/sign-in-sign-up.component';
import { CasinoSearchModalComponent } from './components/casino-search-modal/casino-search-modal.component';

import { DESKTOP_BREAKPOINT } from '../pages-surfpoint/models/surfpoint.const';
import { ROUTE_PATHS } from '../shared/models/routing/routing.const';
import { filterGamesByParams, handleCategoryChange, transformTypesToItemList, transformVendorsToItemList } from './casino.utils';
import { BehaviorSubject, combineLatest, debounceTime } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasinoService {
  private casinoAbstractService: CasinoAbstractService = inject(CasinoAbstractService);
  private platform: Platform = inject(Platform);
  private dialogsService: DialogsService = inject(DialogsService);
  private router: Router = inject(Router);

  private allCasinoGames: Partial<ICasinoGameDetails<any, any>>[] = [];
  public filteredCasinoGames$: BehaviorSubject<Partial<ICasinoGameDetails<any, any>>[]> = new BehaviorSubject<Partial<ICasinoGameDetails<any, any>>[]>([]);

  public categories: Partial<IListItem<number>>[] = [];
  public selectedCategory$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  public providers: Partial<IListItem<string>>[] = [];
  public providers$: BehaviorSubject<Partial<IListItem<string>>[]> = new BehaviorSubject<Partial<IListItem<string>>[]>([]);
  public selectedProviders$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public gameTypes: Partial<IListItem<string>>[] = [];
  public gameTypes$: BehaviorSubject<Partial<IListItem<string>>[]> = new BehaviorSubject<Partial<IListItem<string>>[]>([]);

  public selectedGameType$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public searchKeyword$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public showLoader = true;

  public selectedGame: Partial<ICasinoGameDetails<any, any>> | null = null;

  constructor() {
    this.initialize();
  }

  public async initialize(): Promise<void> {
    this.allCasinoGames = await this.casinoAbstractService.getCasinoGames();
    this.categories = await this.casinoAbstractService.getCasinoCategories();

    if (this.categories[0]) {
      this.handleCasinoCategoryChange(this.categories[0].value);
    }

    this.observeChangesOnFilterParams();
  }

  private observeChangesOnFilterParams(): void {
    combineLatest([this.selectedCategory$, this.selectedProviders$, this.selectedGameType$, this.searchKeyword$])
      .pipe(debounceTime(500))
      .subscribe(([selectedCategory, selectedProviders, selectedGameType, searchKeyword]: [number | undefined, string[], string, string]) => {
        // TODO: On the Casino page, once the search criteria is changed, we might need to do a SCROLL TO TOP on the 'mainIonContentElRef' element
        this.selectedGame = null;

        const filteredCasinoGames: Partial<ICasinoGameDetails<any, any>>[] = filterGamesByParams(
          this.allCasinoGames,
          selectedCategory,
          selectedProviders,
          selectedGameType,
          searchKeyword
        );

        this.filteredCasinoGames$.next(filteredCasinoGames);

        if (this.showLoader) {
          setTimeout(() => {
            this.showLoader = false;
          }, 500);
        }
      });
  }

  public handleCasinoCategoryChange(categoryId: number | undefined): void {
    this.showLoader = true;
    const tmpFilteredGames: Partial<ICasinoGameDetails<any, any>>[] = handleCategoryChange(this.allCasinoGames, categoryId);
    this.providers = transformVendorsToItemList(tmpFilteredGames);
    this.providers$.next(this.providers);
    this.gameTypes = transformTypesToItemList(tmpFilteredGames);
    this.gameTypes$.next(this.gameTypes);

    this.selectedCategory$.next(categoryId);
    this.selectedProviders$.next(this.providers?.map(item => item.value!));
    this.selectedGameType$.next(this.gameTypes[0].value || '');
    this.searchKeyword$.next('');
    // this.selectedCategory = categoryId;
    // this.filteredCasinoGames = handleCategoryChange(this.allCasinoGames, this.selectedCategory);
    // this.providers = transformVendorsToItemList(this.filteredCasinoGames);
    // this.gameTypes = [];
    // setTimeout(() => {
    //   this.gameTypes = transformTypesToItemList(this.filteredCasinoGames);
    // }, 100);
  }

  public handleProvidersChange(providers: string[]): void {
    //this.filteredCasinoGames = filterGamesByType(this.filteredCasinoGames, gameType);
    this.showLoader = true;
    this.selectedProviders$.next(providers);
    this.searchKeyword$.next('');
  }

  public handleSelectedProvidersChange(list: Partial<IListItem<string>>[]): void {
    const selectedProviders: string[] = [];

    list.forEach(provider => {
      if (provider.selected && provider.value) {
        selectedProviders.push(provider.value);
      }
    });

    this.selectedProviders$.next(selectedProviders);
  }

  public handleGameTypeChange(gameType: string): void {
    //this.filteredCasinoGames = filterGamesByType(this.filteredCasinoGames, gameType);
    this.showLoader = true;
    this.selectedGameType$.next(gameType);
    this.searchKeyword$.next('');
  }

  public handleKeywordChange(value: string): void {
    this.searchKeyword$.next(value);
  }

  // public filterCasinoGamesByAllParams(): void {
  //   this.filteredCasinoGames = filterGamesByParams(this.allCasinoGames, this.selectedCategory, this.selectedProviders, this.selectedGameType, this.searchKeyword);
  // }

  public async openSearchModal(): Promise<void> {
    const isMobileResolution: boolean = this.platform?.width() < DESKTOP_BREAKPOINT;
    const initialBreakpoint: number = isMobileResolution ? 1 : this.calculateInitialBreakpoint();

    const dialogResult: OverlayEventDetail<IDialogResponse<any>> = await this.dialogsService.presentModal({
      component: CasinoSearchModalComponent,
      componentProps: {
        showHeader: isMobileResolution
      },
      cssClass: 'is-casino-search-modal',
      initialBreakpoint: initialBreakpoint,
      breakpoints: [0, 0.25, 0.5, initialBreakpoint],
      handleBehavior: 'cycle'
    });
  }

  private calculateInitialBreakpoint(): number {
    const fullHeight = this.platform.height();
    const adjustedHeight = fullHeight - 80; // 80px is the height of the header on desktop
    const heightRatio = adjustedHeight / fullHeight;
    return heightRatio;
  }

  public async handleAuthToPlay(game: Partial<ICasinoGameDetails<any, any>>): Promise<void> {
    const dialogResult: OverlayEventDetail<Partial<IUserDetails> | null> = await this.dialogsService.presentModal({
      component: SignInSignUpComponent,
      componentProps: {
        showHeader: true,
        currentView: 'sign-in'
      }
    });

    if (dialogResult?.role === DIALOG_DISMISS_ROLES.confirm && dialogResult?.data?.id) {
      this.navigateToGame(game);
    }
  }

  public navigateToGame(game: Partial<ICasinoGameDetails<any, any>>): void {
    // this.router.navigate(['game', game?.id], { relativeTo: this.activatedRoute });
    const path = `${ROUTE_PATHS.casino}/game/${game?.id}`;
    this.router.navigateByUrl(path);
  }
}
