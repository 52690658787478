import { inject, Injectable } from '@angular/core';
import { Routes } from '@angular/router';

import { INavGroup } from 'bp-framework/dist/common/common.interface';
import { IEnvApiBase, IEnvConfigPlayerFrontend } from 'bp-framework/dist/configuration/configuration.interface';

import { PROJECT_ENV_CONFIG_TOKEN } from 'bp-angular-library';

import { ContentAbstractService } from '../../env-abstracts';

import { BETPLATFORM_DEV_DEFAULT_ROUTES, BETPLATFORM_DEV_DEFAULT_SIDEMENU, BETPLATFORM_DEV_DEFAULT_SITE_FOOTER_NAVIGATION } from '../routes/routes.dev.default';
import { BETPLATFORM_DEV_JUST_CASINO_ROUTES, BETPLATFORM_DEV_JUST_CASINO_SIDEMENU, BETPLATFORM_DEV_JUST_CASINO_SITE_FOOTER_NAVIGATION } from '../routes/routes.dev.justcasino';
import { BETPLATFORM_DEV_SURFPOINT_ROUTES } from '../routes/routes.dev.surfpoint';
import { BETPLATFORM_PROD_JUST_CASINO_ROUTES, BETPLATFORM_PROD_JUST_CASINO_SIDEMENU, BETPLATFORM_PROD_JUST_CASINO_SITE_FOOTER_NAVIGATION } from '../routes/routes.prod.justcasino';
import { SECONDARY_PAGES_NAV_GROUPS } from '../routes/routes.utils';

@Injectable({
  providedIn: 'root'
})
export class ContentBetplatformService extends ContentAbstractService {
  private projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase> = inject<IEnvConfigPlayerFrontend<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);

  public buildAllRoutes(): Routes {
    if (this.projectConfig?.environmentName === 'prod' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return BETPLATFORM_PROD_JUST_CASINO_ROUTES;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return BETPLATFORM_DEV_JUST_CASINO_ROUTES;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'surfpoint') {
      return BETPLATFORM_DEV_SURFPOINT_ROUTES;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'default') {
      return BETPLATFORM_DEV_DEFAULT_ROUTES;
    } else {
      return [];
    }
  }

  public buildSidemenuRoutes(): Partial<INavGroup>[] {
    if (this.projectConfig?.environmentName === 'prod' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return BETPLATFORM_PROD_JUST_CASINO_SIDEMENU;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return BETPLATFORM_DEV_JUST_CASINO_SIDEMENU;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'surfpoint') {
      return [];
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'default') {
      return BETPLATFORM_DEV_DEFAULT_SIDEMENU;
    } else {
      return [];
    }
  }

  public buildMainMenuRoutes(): Partial<INavGroup>[] {
    // TODO: Check why we have INavigationItem and INavGroup? They have difference in the naming of their properties (eg, labelT9nKey vs t9nKey)
    if (this.projectConfig?.environmentName === 'prod' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return BETPLATFORM_PROD_JUST_CASINO_SIDEMENU;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return BETPLATFORM_DEV_JUST_CASINO_SIDEMENU;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'surfpoint') {
      return [];
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'default') {
      return [];
    } else {
      return [];
    }
  }

  public buildSiteFooterNavLinks(): Partial<INavGroup>[] {
    if (this.projectConfig?.environmentName === 'prod' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return BETPLATFORM_PROD_JUST_CASINO_SITE_FOOTER_NAVIGATION;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'justcasino') {
      return BETPLATFORM_DEV_JUST_CASINO_SITE_FOOTER_NAVIGATION;
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'surfpoint') {
      return [];
    } else if (this.projectConfig?.environmentName === 'dev' && this.projectConfig?.features?.frontendMode === 'default') {
      return BETPLATFORM_DEV_DEFAULT_SITE_FOOTER_NAVIGATION;
    } else {
      return [];
    }
  }

  public buildSecondaryPagesNavLinks(): Partial<INavGroup>[] {
    return SECONDARY_PAGES_NAV_GROUPS;
  }
}
