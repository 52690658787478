import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateService } from '@ngx-translate/core';

import { IPlayer, ITokenPayload, IUser } from 'bp-framework/dist/env-specific/betplatform/user/user.interface';
import { extractUserDetailsFromToken, mergeUserDetailsWithProfileData } from 'bp-framework/dist/env-specific/betplatform/user/user.mappers';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { AuthService, BpCoreApiService, I18nService } from 'bp-angular-library';

import { UserAbstractService } from '../../env-abstracts';

@Injectable({
  providedIn: 'root'
})
export class UserBetplatformService extends UserAbstractService {
  private bpCoreApiService: BpCoreApiService = inject(BpCoreApiService);
  private translateService: TranslateService = inject(TranslateService);
  private i18nService: I18nService = inject(I18nService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private authService: AuthService = inject(AuthService);

  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        // TODO: Ensure that both responses are successful before proceeding. If not, reject the promise. We don't want to proceed if the Login or GetProfile fails.
        const loginPayload: ITokenPayload | null = await this.bpCoreApiService.authenticateWithUsernameAndPassword(username, password);

        if (!loginPayload?.access_token) {
          return reject(new Error(this.translateService.instant('notifications.failedToLoginCheckCredentials')));
        }

        const transformedLoginData: Partial<IUserDetails> | null = extractUserDetailsFromToken(loginPayload);

        await this.authService.userAuthChanged(transformedLoginData);

        const getProfilePayload: IPlayer | null = await this.bpCoreApiService.getPlayerProfile();

        if (!getProfilePayload) {
          return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
        }

        const currentDetails: Partial<IUserDetails> | null = this.authService.user$.value;

        const mergedValue: Partial<IUserDetails> | null = mergeUserDetailsWithProfileData(currentDetails, getProfilePayload);

        await this.authService.userAuthChanged(mergedValue);

        resolve(mergedValue);
      } catch (error) {
        // TODO: Check if we can present error message instead of presenting custom message without any context.
        // TODO: Revisit error handling and error messages in the entire environment adapter
        return reject(new Error(this.translateService.instant('notifications.failedToLoginCheckCredentialsOrTryLater')));
      }
    });
  }

  public async refreshToken(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        // TODO: Ensure that both responses are successful before proceeding. If not, reject the promise. We don't want to proceed if the Login or GetProfile fails.
        const refreshToken: string | undefined = await this.authService.user$.value?.auth?.refreshToken;

        if (!refreshToken) {
          return reject(new Error(this.translateService.instant('notifications.failedToRetreiveRefreshToken')));
        }

        const loginPayload: ITokenPayload | null = await this.bpCoreApiService.refreshToken(refreshToken);

        if (!loginPayload?.access_token) {
          return reject(new Error(this.translateService.instant('notifications.failedToRefreshToken')));
        }

        await this.authService.userAuthChanged(extractUserDetailsFromToken(loginPayload));

        const getProfilePayload: IPlayer | null = await this.bpCoreApiService.getPlayerProfile();

        if (!getProfilePayload) {
          return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
        }

        const currentDetails: Partial<IUserDetails> | null = this.authService.user$.value;

        const mergedValue: Partial<IUserDetails> | null = mergeUserDetailsWithProfileData(currentDetails, getProfilePayload);

        await this.authService.userAuthChanged(mergedValue);

        resolve(mergedValue);
      } catch (error: unknown) {
        return reject(error);
      }
    });
  }

  // TODO: Revisit naming of this method. It should be more descriptive. Everyone are 'user' but we also have 'player' and 'profile' in the same context
  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: IPlayer | null = await this.bpCoreApiService.getPlayerProfile();
        resolve(userProfile ? mergeUserDetailsWithProfileData({}, userProfile) : null);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
      }
    });
  }

  public async patchUserLanguage(lang: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: IUser | null = await this.bpCoreApiService.patchUserLanguage({
          language: lang
        });

        resolve(userProfile ? mergeUserDetailsWithProfileData({}, userProfile) : null);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUserProfileData')));
      }
    });
  }

  public async updateUserWithProfileData(): Promise<Partial<IUserDetails> | null> {
    const freshData: Partial<IUserDetails> | null = await this.getUserProfile();
    await this.authService.userDetailsChanged(freshData);
    return this.authService.user$.value;
  }

  public watchForLanguageChange(): void {
    this.i18nService.saveNewlySelectedLanguageToUserProfile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((langAlpha2Code: string) => {
      // Handle additional actions on language change, e.g., refreshing data, updating UI, etc.
      // Update user language in the backend
      if (this.authService.user$.value?.id) {
        this.patchUserLanguage(langAlpha2Code);
        this.authService.userDetailsChanged({ attributes: { preferredLang: langAlpha2Code } });
      }
    });
  }
}
