<div class="ion-page">
  <ng-container *ngIf="showHeader">
    <ion-header>
      <ion-toolbar>
        <ion-title>SEARCH</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="handleClose()"><span class="ph ph-x" slot="icon-only"></span></ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
  </ng-container>
  <ion-content [scrollY]="false">
    <div class="has-header-and-main">
      <div class="has-header-content">
        <div class="has-searchbar">
          <app-casino-searchbar class="is-searchbar" [setAutoFocus]="true" (handleInputChange)="handleInputChange($event)"></app-casino-searchbar>
          <div class="is-search-hint-text">Search requires at least 3 characters</div>
        </div>
      </div>
      <div class="has-main-content">
        <div class="content-wrapper">
          <h4>List of games here</h4>
          <h4>List of games here</h4>
          <h4>List of games here</h4>
        </div>
      </div>
    </div>
  </ion-content>
</div>
